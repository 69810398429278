import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Binary IO",
  "time": "Summer 2022",
  "index": 9,
  "type": "project",
  "hidden": false,
  "splash": "Directly read and write binary files using a traditional text editor"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const GitLink = makeShortcode("GitLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1><a parentName="h1" {...{
        "href": "https://github.com/DavidPeet8/BinaryIO"
      }}>{`Binary IO`}</a></h1>
    <p><strong parentName="p">{`Date Range:`}</strong>{` 2020`}</p>
    <p><strong parentName="p">{`Key Technologies:`}</strong>{` `}<inlineCode parentName="p">{`C++`}</inlineCode>{` `}<inlineCode parentName="p">{`CMake`}</inlineCode></p>
    <p><strong parentName="p">{`Github Source:`}</strong>{` `}<GitLink url="https://github.com/DavidPeet8/BinaryIO" mdxType="GitLink" /></p>
    <hr></hr>
    <p>{`Although impractical for `}<em parentName="p">{`binary patching`}</em>{`, Binary IO is a useful tool for understanding binary formats and investigating small non human readable files. I created Binary IO in my spare time while working at Huawei to assist me in understanding the compiler object file format used.`}</p>
    <p>{`What I wanted to create with this project was a tool to give me true insight into file content. I wanted to remove endian conversions and other magic that mainstream tools apply automatically for usability improvements, and instead provide a tool that `}<em parentName="p">{`"tells it like it is"`}</em>{`. I found this particularly useful for understanding & generating binary file formats.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      